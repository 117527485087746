<template>
  <div class="mod-config">
    
    <div class="wxts_msg_search">
      <div class="f_t">
          <el-button-group>
            <el-button size="mini" v-if="isAuth('sys:sysdept:save')" v-show="this.treeNode.type == 'org' " type="primary" @click="addOrUpdateHandleOrg()">新增组织</el-button>
            <el-button size="mini" v-if="isAuth('sys:sysdept:save')" v-show="this.treeNode.type == 'org' || this.treeNode.type == 'dept'"  type="primary" @click="addOrUpdateHandleDept()">新增部门</el-button>
            <el-button size="mini" v-if="isAuth('sys:sysdept:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
          </el-button-group>
        </div>
    </div>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          prop="NAME"
          header-align="center"
          align="center"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="codeId"
          header-align="center"
          align="center"
          label="编码">
      </el-table-column>
      <el-table-column
          prop="parentName"
          header-align="center"
          align="center"
          label="上级名称">
      </el-table-column>
      <el-table-column 
         prop="regionName"
         header-align="center"
         align="center"
         label="所属区域">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="orderBy"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          label="排序编号">-->
<!--      </el-table-column>--> 
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="isAuth('sys:sysdept:update') "
                     @click="addOrUpdateHandle(scope.row)">修改</el-button>
          <el-button type="text" size="small" class="del-button" v-if="isAuth('sys:sysdept:delete')"  @click="deleteHandle(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <addOrUpdateOrg v-if="addOrUpdateVisible" ref="addOrUpdateOrg" @treeget='treeget' @refreshDataList="getDataList" @destoryOrg="addOrUpdateVisible = false"></addOrUpdateOrg>
    <addOrUpdateDept v-if="addOrUpdateVisible" ref="addOrUpdateDept" @treeget='treeget' @refreshDataList="getDataList"></addOrUpdateDept>
  </div>
</template>

<script>
import addOrUpdateOrg from './org-add-or-update'
import addOrUpdateDept from './dept-add-or-update'
import { getList } from '@/api/sys/sysdept.js'
import { deleteData } from '@/api/sys/sysdept.js'
import { deleteTree} from '@/api/sys/sysorg.js'

export default {
  data () {

    return {
      dataForm: {
        key: ''
      },
      treeNode: {
        id: '',
        type: '',
        orgId: ''
      }, 
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    addOrUpdateOrg,addOrUpdateDept
  },
  activated () {
    //this.dataForm.key =  this.$route.query.key;

    let pageIndex = this.$route.query.pageIndex;
    if(pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if(pageSize) {
      this.pageSize = parseInt(pageSize);
    }

    this.getDataList();
  },
  methods: {
    treeget(){
      this.$emit('getTreeList')
    },

    init(treeNode){
      this.treeNode = treeNode; 
      this.getDataList();
    },

    refresh(){
      // this.$router.push({name:'dataflow_sysdept',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      const params_ = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'key': this.dataForm.key,
        'id': this.treeNode.id,
        'type':this.treeNode.type
      }

      getList(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
          //this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(()=>{
        this.dataListLoading = false
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改 组织
    addOrUpdateHandleOrg (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
          this.$refs.addOrUpdateOrg.init(id,this.treeNode)
      })
    },

    //新增  修改  部门
    addOrUpdateHandleDept (id) {
      //debugger
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
          this.$refs.addOrUpdateDept.init(id,this.treeNode)
      })
    },

    addOrUpdateHandle(row){
      if(row.orgId == "" || row.orgId == null){
        this.addOrUpdateHandleOrg(row.id)
      }else {
        this.addOrUpdateHandleDept(row.id)
      }
    },

    // 删除
    deleteHandle (row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })

      this.$confirm(`将会把下级组织或部门一起删除掉，确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteTree(ids).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
            this.treeget()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(()=>{
          this.dataListLoading = false
        })
      }).catch(_ => {})
    }
  }
}
</script>
